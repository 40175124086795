body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

root {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Font Face used to load different fonts from TTF files */
@font-face {
  font-family: "AmazinglyBeautifulRegular";
  src: local("AmazinglyBeautifulRegular"),
    url("./fonts/AmazinglyBeautifulRegular.ttf") format("truetype");
}

@font-face {
  font-family: "Almondbutter";
  src: local("AlmondButter"), url("./fonts/almondbutter.ttf") format("truetype");
}

@font-face {
  font-family: "Andrea";
  src: local("Andrea"), url("./fonts/Andrea.ttf") format("truetype");
}

@font-face {
  font-family: "aurora";
  src: local("aurora"), url("./fonts/aurora.ttf") format("truetype");
}

@font-face {
  font-family: "Autery";
  src: local("Autery"), url("./fonts/Autery.ttf") format("truetype");
}

@font-face {
  font-family: "Babypoint";
  src: local("Babypoint"), url("./fonts/Babypoint.ttf") format("truetype");
}

@font-face {
  font-family: "Bakedfresh";
  src: local("Bakedfresh"), url("./fonts/Bakedfresh.ttf") format("truetype");
}

@font-face {
  font-family: "Brittany";
  src: local("Brittany"), url("./fonts/BrittanySignature.ttf") format("truetype");
}

@font-face {
  font-family: "Brooklyn";
  src: local("Brooklyn"), url("./fonts/Brooklyn.ttf") format("truetype");
}

@font-face {
  font-family: "blossom";
  src: local("blossom"), url("./fonts/blossom.ttf") format("truetype");
}

@font-face {
  font-family: "BohoFlowers";
  src: local("BohoFlowers"), url("./fonts/BohoFlowers.otf") format("opentype");
}

	@font-face {
  font-family: "Brooklyn";
  src: local("Brooklyn"), url("./fonts/Brooklyn.ttf") format("truetype");
}

@font-face {
  font-family: "Chakra";
  src: local("Chakra"), url("./fonts/Chakra.ttf") format("truetype");
}

@font-face {
  font-family: "Chalk";
  src: local("Chalk"), url("./fonts/Chalk.ttf") format("truetype");
}

@font-face {
  font-family: "ChristmasMint";
  src: local("ChristmasMint"), url("./fonts/xmasMint.ttf") format("truetype");
}

@font-face {
  font-family: "classico";
  src: local("classico"), url("./fonts/classico.ttf") format("truetype");
}

@font-face {
  font-family: "Clocks";
  src: local("Clocks"), url("./fonts/Clocks.ttf") format("truetype");
}

@font-face {
  font-family: "Cookie";
  src: local("Cookie"), url("./fonts/Cookie.ttf") format("truetype");
}

@font-face {
  font-family: "Doodle";
  src: local("Doodle"), url("./fonts/Doodle.ttf") format("truetype");
}

@font-face {
  font-family: "Eduarda";
  src: local("Eduarda"), url("./fonts/Eduarda.ttf") format("truetype");
}

@font-face {
  font-family: "Farmhouse";
  src: local("Farmhouse"), url("./fonts/Farmhouse.ttf") format("truetype");
}

@font-face {
  font-family: "Ghisella";
  src: local("Ghisella"), url("./fonts/Ghisella.ttf") format("truetype");
}

@font-face {
  font-family: "Hancock";
  src: local("Hancock"), url("./fonts/Hancock.ttf") format("truetype");
}

@font-face {
  font-family: "Harpsichord";
  src: local("Harpsichord"), url("./fonts/Harpsichord.ttf") format("truetype");
}

@font-face {
  font-family: "Langrish";
  src: local("Langrish"), url("./fonts/Langrish.ttf") format("truetype");
}

@font-face {
  font-family: "Layla";
  src: local("Layla"), url("./fonts/Layla.ttf") format("truetype");
}

@font-face {
  font-family: "Lively";
  src: local("Lively"), url("./fonts/Lively.ttf") format("truetype");
}

@font-face {
  font-family: "Tatertot";
  src: local("Tatertot"), url("./fonts/Tatertot.ttf") format("truetype");
}

@font-face {
  font-family: "Sweetheart";
  src: local("Sweetheart"), url("./fonts/Sweetheart.ttf") format("truetype");
}


@font-face {
  font-family: "Violetta";
  src: local("Violetta"), url("./fonts/Violetta.ttf") format("truetype");
}

@font-face {
  font-family: "Wonderful";
  src: local("Wonderful"), url("./fonts/Wonderful.otf") format("opentype");
}

/* Classes used by different conponents to change font */

.font-face-Almondbutter {
  font-family: "Almondbutter";
}

.font-face-Amazingly {
  font-family: "AmazinglyBeautifulRegular";
}

.font-face-Andrea {
  font-family: "Andrea";
}

.font-face-aurora {
  font-family: "aurora";
}

.font-face-Autery {
  font-family: "Autery";
}

.font-face-Babypoint {
  font-family: "Babypoint";
}

.font-face-Bakedfresh {
  font-family: "Bakedfresh";
}

.font-face-blossom {
  font-family: "blossom";
}

.font-face-BohoFlowers {
  font-family:"BohoFlowers";
}

.font-face-Brittany {
  font-family: "Brittany";
}

.font-face-Brooklyn {
  font-family: "Brooklyn";
}

.font-face-classico {
  font-family: "classico";
}

.font-face-Chakra {
  font-family: "Chakra";
}

.font-face-Chalk {
  font-family: "Chalk";
}

.font-face-Clocks {
  font-family: "Clocks";
}

.font-face-Cookie {
  font-family: "Cookie";
}

.font-face-Doodle {
  font-family: "Doodle";
}

.font-face-Eduarda {
  font-family: "Eduarda";
}

.font-face-Farmhouse {
  font-family: "Farmhouse";
}

.font-face-Ghisella {
  font-family: "Ghisella";
}

.font-face-Hancock {
  font-family: "Hancock";
}

.font-face-Harpsichord {
  font-family: "Harpsichord";
}

.font-face-Langrish {
  font-family: "Langrish";
}

.font-face-Layla {
  font-family: "Layla";
}

.font-face-Lively {
  font-family: "Lively";
}

.font-face-Tatertot {
  font-family: "Tatertot";
}

.font-face-Sweetheart {
  font-family: "Sweetheart";
}

.font-face-ChristmasMint {
  font-family: "ChristmasMint";
}

.font-face-Violetta {
  font-family: "Violetta";
}

.font-face-Wonderful {
  font-family: "Wonderful";
}